import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useForm, Controller, useWatch } from "react-hook-form";
import { addDays, addYears } from "date-fns";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CAR_PACKAGE_PATH } from "configs/page";
import { MailContactType } from "configs/common";
import Button from "components/Button";
import Select from "components/Input/Select";
import SelectCarBrand from "components/Input/SelectCarBrand";
import { Status, Language } from "types/common.d";
import { ICarInfoForm } from "types/car-info.d";
import { carInfoState } from "states/carInfoState";
import { searchParamsState } from "states/searchParamsState";
import { carPackageState } from "states/carPackageState";
import { useBrands } from "hooks/useBrand";
import { useModels } from "hooks/useModel";
import { useYears } from "hooks/useYear";
import { useRegProvinces } from "hooks/useRegProvince";
import { IOCRData } from "types/book-ocr.d";
import { roundUpNearest100 } from "utils/formatter";
import DialogContactStaff from "components/Dialog/DialogContactStaff";
import Calendar from "components/Input/Calendar";
import { IModel } from "types/model.d";
import { useCommonState } from "hooks/useCommonState";

interface OcrProps {
  ocrData: IOCRData;
}

const BookRegisterForm = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const resetCarPackage = useResetRecoilState(carPackageState);
  const [carInfo, setCarInfo] = useRecoilState(carInfoState);
  const searchParams = useRecoilValue(searchParamsState);
  const { setLoading } = useCommonState();
  const { state } = useLocation();
  const ocrState = state as OcrProps;
  const ocrData = ocrState?.ocrData;
  const [isFinishOcrPreset, setIsFinishOcrPreset] = useState(
    !ocrData || !!carInfo.insuranceCoverageStartDate
  );
  const [isOpenDialogContactStaff, setIsOpenDialogContactStaff] =
    useState<boolean>(false);

  const [modelDoorFilterOption, setModelDoorFilterOption] = useState<
    IModel[] | null
  >(null);

  const today = new Date();
  const maxDate = addDays(today, 60);

  const defaultValues: ICarInfoForm = {
    brand: carInfo.brand ?? null,
    model: carInfo.model ?? null,
    year: carInfo.year ?? null,
    regProvince: carInfo.regProvince ?? null,
    insuranceCoverageStartDate: carInfo.insuranceCoverageStartDate ?? today,
  };

  const schema = yup
    .object({
      brand: yup
        .object()
        .required(t("carInfo.inputError.brandRequired"))
        .nullable(),
      model: yup
        .object()
        .required(t("carInfo.inputError.modelRequired"))
        .nullable(),
      year: yup
        .object()
        
        .nullable(),
      regProvince: yup
        .object()
        .required(t("carInfo.inputError.regProvinceRequired"))
        .nullable(),
      insuranceCoverageStartDate: yup
        .date()
        .min(
          new Date(today.setHours(0, 0, 0, 0)),
          t("carInfo.inputError.insuranceCoverageStartDate.minDate")
        )
        .max(
          new Date(maxDate.setHours(23, 59, 59, 999)),
          t("carInfo.inputError.insuranceCoverageStartDate.maxDate")
        )
        .typeError(t("carInfo.inputError.insuranceCoverageStartDateRequired"))
        .required(t("carInfo.inputError.insuranceCoverageStartDateRequired")),
    })
    .required();

  const { control, handleSubmit, setValue } = useForm<ICarInfoForm>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
  });

  const {
    brand: brandSelected,
    model: modelSelected,
    year: yearSelected,
    regProvince: regProvinceSelected,
  } = useWatch({ control });

  const { data: brandList, isLoading: isBrandLoading } = useBrands({
    I_Key: "*",
    I_Type: "ALL",
    I_Option: Status.Active,
    I_Language: Language.th,
  });

  const { data: modelList } = useModels(
    {
      I_Key: brandSelected?.makeCode ?? "",
      I_Type: "BRANDCODE",
      I_Option: Status.ModelActiveConfig,
      I_Language: Language.th,
    },
    !!brandSelected
  );

  const { data: yearList } = useYears(
    {
      I_Key: modelSelected?.mtiKey ?? "",
      I_Type: "MTIKEY",
      I_EngineSize: modelSelected?.engineSize ?? "",
      I_DoorNum: modelSelected?.doorNum ?? "",
      I_BodyConfig: modelSelected?.bodyConfig ?? "",
      I_Option: Status.Active,
      I_Language: Language.th,
      I_FuelType: modelSelected?.fuelType ?? "",
    },
    !!modelSelected
  );

  const { data: regProvinceList, isLoading: isRegLoading } = useRegProvinces();

  const isLoadingAll = isBrandLoading || isRegLoading || !isFinishOcrPreset;

  useEffect(() => {
    setLoading(isLoadingAll);
  }, [isLoadingAll, setLoading]);

  useEffect(() => {
    if (ocrData && !isFinishOcrPreset && brandList?.length && !brandSelected) {
      const ocrBrand = brandList.find(
        (brand) => brand.brand === ocrData.brandCar
      );
      if (ocrBrand) {
        setValue("brand", ocrBrand);
      } else {
        setIsFinishOcrPreset(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandList]);

  useEffect(() => {
    if (ocrData && !isFinishOcrPreset && modelList?.length && !modelSelected) {
      const ocrModel = modelList.filter(
        (model) =>
          model.family.includes(ocrData.model) &&
          +model.engineSize === roundUpNearest100(+ocrData.engineSize) &&
          model.fuelType === ocrData.typeFuel
      );
      // ocr cannot read door number
      // length === 1 means only one option match ocr
      if (ocrModel?.length === 1) {
        setValue("model", ocrModel[0]);
      } else if (ocrModel?.length) {
        setModelDoorFilterOption(ocrModel);
        setIsFinishOcrPreset(true);
      } else {
        setIsFinishOcrPreset(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelList]);

  useEffect(() => {
    if (ocrData && !isFinishOcrPreset && yearList?.length && !yearSelected) {
      const ocrYear = yearList.find(
        (year) => year.yearGroup.toString() === ocrData.yearModel
      );
      if (ocrYear) {
        setValue("year", ocrYear);
      }
      setIsFinishOcrPreset(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearList]);

  useEffect(() => {
    if (
      ocrData &&
      !isFinishOcrPreset &&
      regProvinceList?.length &&
      !regProvinceSelected
    ) {
      const regProvince = regProvinceList.find(
        (reg) => reg.province === ocrData.province
      );
      if (regProvince) {
        setValue("regProvince", regProvince);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regProvinceList]);

  const onSubmit = (dataForm: ICarInfoForm) => {
    const updateCarInfo = {
      ...dataForm,
      insuranceCoverageEndDate: addYears(
        new Date(dataForm.insuranceCoverageStartDate ?? ""),
        1
      ),
      ocrData,
      carUniqueInfo: carInfo.carUniqueInfo ?? null,
    };
    setCarInfo(updateCarInfo);

    // case already custom form package and back to change car info
    if (JSON.stringify(dataForm.year) !== JSON.stringify(carInfo.year)) {
      resetCarPackage();
    }

    navigate(CAR_PACKAGE_PATH);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-3" data-testid="bookregister-brand">
          <Controller
            control={control}
            name="brand"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectCarBrand
                label={t("carInfo.brand")}
                onChange={(e) => {
                  setModelDoorFilterOption(null);
                  setValue("model", null);
                  setValue("year", null);
                  onChange(e);
                }}
                value={value}
                options={brandList}
                placeholder={t("carInfo.brand")}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-3" data-testid="bookregister-model">
          <Controller
            control={control}
            name="model"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={t("carInfo.model")}
                keyTitle="modelName"
                onChange={(e) => {
                  setValue("year", null);
                  onChange(e);
                }}
                value={value}
                options={modelDoorFilterOption ?? modelList}
                placeholder={t("carInfo.model")}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-3" data-testid="bookregister-year">
          <Controller
            control={control}
            name="year"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={t("carInfo.year")}
                keyTitle="yearGroup"
                onChange={onChange}
                value={value}
                options={yearList}
                placeholder={t("carInfo.year")}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        {/* <div className="py-3">
          <Controller
            control={control}
            name="engineSize"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={t("carInfo.engineSize")}
                keyTitle="engineSize"
                onChange={onChange}
                value={value}
                options={subModelList}
                placeholder={t("carInfo.engineSize")}
                errorMsg={error?.message}
              />
            )}
          />
        </div> */}
        <div className="py-3" data-testid="bookregister-regProvince">
          <Controller
            control={control}
            name="regProvince"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={t("carInfo.regProvince")}
                keyTitle="province"
                onChange={onChange}
                value={value}
                options={regProvinceList}
                placeholder={t("carInfo.regProvince")}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-3">
          <Controller
            control={control}
            name="insuranceCoverageStartDate"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Calendar
                label={t("carInfo.insuranceCoverageStartDate")}
                onChange={onChange}
                value={value}
                minDate={today}
                maxDate={maxDate}
                errorMsg={error?.message}
                placeholder={t("carInfo.insuranceCoverageStartDate")}
              />
            )}
          />
        </div>
        <div className="py-3">
          <Button
            fullWidth
            type="submit"
            variant="gradient"
            color="primary"
            size="large"
            data-testid="carbook-register-confirm-btn"
          >
            {t("confirmBtn")}
          </Button>
        </div>
        <div className="py-3">
          <Button
            fullWidth
            variant="gradient"
            color="secondary"
            size="large"
            onClick={() => {
              setIsOpenDialogContactStaff(true);
            }}
            data-testid="carbook-register-contact-btn"
          >
            {t("contactAdmin")}
          </Button>
        </div>
      </form>
      {!!searchParams?.debug && (
        <p className="break-all">{JSON.stringify(ocrData)}</p>
      )}
      <DialogContactStaff
        contactType={MailContactType.CarDetail}
        open={isOpenDialogContactStaff}
        carInfo={{
          makeCode: brandSelected?.makeCode,
          family: modelSelected?.family,
          modelName: modelSelected?.modelName,
          yearGroup: yearSelected?.yearGroup,
        }}
        onCloseDialog={() => {
          setIsOpenDialogContactStaff(false);
        }}
      />
      <DevTool control={control} />
    </>
  );
};

export default BookRegisterForm;
