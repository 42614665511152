export const configs = {
  env: process.env.REACT_APP_ENVIRONMENT ?? "",
  appUrl: window.location.origin ?? "",

  mtiApiKey: process.env.REACT_APP_MTI_API_KEY ?? "",
  mtiApiUsername: process.env.REACT_APP_MTI_API_USERNAME ?? "",
  mtiApiPassword: process.env.REACT_APP_MTI_API_PASSWORD ?? "",
  mtiApiPartnerCode: process.env.REACT_APP_MTI_API_PARTNER_CODE ?? "",
  mtiApiBasicAuth: process.env.REACT_APP_MTI_API_BASIC_AUTH ?? "",

  ocrUsername: process.env.REACT_APP_OCR_USERNAME ?? "",
  ocrPassword: process.env.REACT_APP_OCR_PASSWORD ?? "",

  apiUrl:
    process.env.REACT_APP_ENVIRONMENT === "uat" ||
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? process.env.REACT_APP_MTI_API_URL
      : `${process.env.REACT_APP_API_URL}/adapter/api/proxy/v1/mti`,
  caraiApiUrl: `${process.env.REACT_APP_API_URL}/adapter/api/partner/v1/mti`,
  caraiBaseApiUrl: `${process.env.REACT_APP_API_URL}/api/base/v1`,
  caraiBaseApiPublicKey: process.env.REACT_APP_API_PUBLIC_KEY ?? "",
  callbackApiUrl: `${process.env.CALL_BACK_API_URL}/validate/v1/Callback`,

  sentryLogKey: process.env.REACT_APP_SENTRY_DSN ?? "",

  initialCode: "MTI",
  contactNumber: "02 290 3366",
  contactEmail: "IOCC-E-Commerce@muangthaiinsurance.com",
  officeHours: "09:00 - 20:00 น.",
  telLink: "tel://022903366",
};
