import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { idCardState } from "states/idCardState";
import { SUMMARY_PATH, LANDING_PATH } from "configs/page";
import { configs } from "configs/config";
import { useKYCGenerateToken, useKYCValidation } from "hooks/useKYC";
import { useCommonState } from "hooks/useCommonState";
import KYCConfirmCard from "./components/KYCConfirmCard";

const TIME_INTERVAL = 5000;

const KYCPage = (): JSX.Element => {
  const {
    tokenData,
    generateToken,
    isLoading: isLoadingGenToken,
    error,
  } = useKYCGenerateToken();
  const {
    kycData,
    validateKYC,
    isLoading,
    error: errorKYCValidation,
  } = useKYCValidation();
  const navigate = useNavigate();
  const { setLoading } = useCommonState();
  const userInfo = useRecoilValue(idCardState);
  const [openKYC, setOpenKYC] = useState<boolean>(false);
  const [urlKYC, setUrlKYC] = useState<string>("");

  const generateTokenKYC = () => {
    const params = {
      keyId: userInfo.user.id,
      owner: configs.mtiApiPartnerCode,
    };
    generateToken(params);
  };

  const gotoNextStep = () => {
    navigate(SUMMARY_PATH, { replace: true });
  };

  useEffect(() => {
    if (!openKYC) {
      setLoading(isLoading || isLoadingGenToken);
    }
  }, [isLoading, isLoadingGenToken, openKYC, setLoading]);

  useEffect(() => {
    if (!userInfo.isInsurer) return gotoNextStep();

    generateTokenKYC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openKYC && tokenData?.accessToken) {
      const interval = setInterval(() => {
        validateKYC(tokenData.accessToken, {});
      }, TIME_INTERVAL);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openKYC]);

  useEffect(() => {
    if (tokenData?.accessToken) {
      validateKYC(tokenData.accessToken);
    }
  }, [tokenData, validateKYC]);

  useEffect(() => {
    const transaction = localStorage.getItem("transaction");

    if (kycData?.pass) {
      // Check if kycData.validates.data is an array
      if (kycData.validates != null) {
        if (Array.isArray(kycData.validates.data)) {
          const target = kycData.validates.data.find(
            (item) =>
              item.refNo === transaction && item.dataType === "INSURE_EKYC"
          );

          if (target) {
            navigate(SUMMARY_PATH, { replace: true });
          }
        }
      }
    } else if (kycData?.url) {
      setUrlKYC(kycData.url);
    }
  }, [kycData, navigate]);

  const onClickCancelKYC = () => {
    window.location.href = LANDING_PATH;
  };

  const onClickConfirmKYC = () => {
    setOpenKYC(true);
  };

  return (
    <>
      {openKYC && urlKYC ? (
        <iframe id="kyc-iframe" title="kyc" src={urlKYC} allow="camera" />
      ) : (
        userInfo.isInsurer && (
          <div className="p-3">
            <KYCConfirmCard
              disabledSubmitBtn={!!error || !!errorKYCValidation}
              onConfirm={onClickConfirmKYC}
              onCancel={onClickCancelKYC}
            />
          </div>
        )
      )}
    </>
  );
};

export default KYCPage;
