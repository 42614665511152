import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { carDetectionState } from "states/carDetectionState";
import Button from "components/Button";
import DialogContactStaff from "components/Dialog/DialogContactStaff";
import DialogConfirm from "components/Dialog/DialogConfirm";
import { useCommonState } from "hooks/useCommonState";
import {
  useCarDetection,
  useCarSubmit,
  isOneTimeTokenExpired,
  handleTokenError,
} from "hooks/useCarDetection";
import { configs } from "configs/config";
import { AdapterSystem, MailContactType } from "configs/common";
import {
  AiFailReason,
  AiFailType,
  carConfig,
  carConfigLength,
} from "configs/car";
import { CAR_SUCCESS_PATH, NOTFOUND_PATH } from "configs/page";
import { searchParamsState } from "states/searchParamsState";
import { getAllImage } from "utils/car-detection";
import { ImageStatus } from "types/car-detection.d";
import CarDetailCard from "./Components/CarDetailCard";
import { IAdapterError } from "types/common";

const TIME_INTERVAL = 2000; // refresh /car api every 2 seconds
const FAIL_REASON_IMAGE_TYPE = {
  code: "FailImageType",
  car: "FailImageTypeCar",
  dashboard: "FailImageTypeDashboard",
};

const CarInfoPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carDetectionInfo, setCarDetectionState] =
    useRecoilState(carDetectionState);
  const searchParams = useRecoilValue(searchParamsState);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [isOpenDialogContactStaff, setIsOpenDialogContactStaff] =
    useState<boolean>(false);
  const refNo = searchParams?.refNo ?? "";
  const carInspectionId = carDetectionInfo?.carInspectionId ?? "";
  const carImage =
    carDetectionInfo?.images && getAllImage(carDetectionInfo.images);

  const { carDetectionMutate, isLoading } = useCarDetection();
  const { carSubmitMutate } = useCarSubmit(carInspectionId);
  const { setLoading, setWarningDialog } = useCommonState();
  const isLoadingFirstTime = isLoading && !carDetectionInfo;

  useEffect(() => {
    setLoading(isLoadingFirstTime);
  }, [isLoadingFirstTime, setLoading]);

  useEffect(() => {
    if (!refNo) {
      navigate(NOTFOUND_PATH);
      return;
    }

    if (TIME_INTERVAL) {
      let isLoadingLoop = false;
      let interval = setInterval(() => {
        // in case api more than TIME_INTERVAL if not api refetch before success
        if (!isLoadingLoop) handleCheckCarStatus();
      }, TIME_INTERVAL);

      const handleCheckCarStatus = () => {
        isLoadingLoop = true;
        const carDetectionBody = {
          companyRef: { referenceNo: refNo },
        };

        carDetectionMutate(carDetectionBody, {
          onSuccess(resp) {
            const data = resp?.data ?? null;
            setCarDetectionState(data);

            const images = data?.images && getAllImage(data.images);
            let progressIsDone = 0;
            let progressIsInAIProcess = 0;

            if (images && Object.keys(images).length) {
              Object.values(images).forEach((image) => {
                if (image?.status === ImageStatus.completed) {
                  progressIsDone++;
                } else if (image?.status) {
                  progressIsInAIProcess++;
                }
              });

              if (
                progressIsInAIProcess === 0 &&
                progressIsDone <= carConfigLength
              ) {
                clearInterval(interval);
              }

              if (progressIsDone === carConfigLength) {
                setDisabledSubmit(false);
              } else {
                setDisabledSubmit(true);
              }
            }
            isLoadingLoop = false;
          },
          onError(error: IAdapterError) {
            clearInterval(interval);

            const isTokenError = isOneTimeTokenExpired(error);
            if (isTokenError) {
              handleTokenError(searchParams);
              return;
            }

            const system = error?.response?.data?.system;
            if (system === AdapterSystem.MTI) {
              setWarningDialog({
                title: t("warning.mti.common"),
                message: t("warning.mti.retry"),
                onClose: () => {
                  interval = setInterval(() => {
                    handleCheckCarStatus();
                  }, TIME_INTERVAL);
                  setWarningDialog(null);
                },
              });
            } else {
              setWarningDialog({
                title: t("warning.carInfo.title"),
                subTitle: t("warning.carInfo.detail", {
                  contactNumber: configs.contactNumber,
                  officeHours: configs.officeHours,
                }),
                onSubmit: () => {
                  interval = setInterval(() => {
                    handleCheckCarStatus();
                  }, TIME_INTERVAL);
                  setWarningDialog(null);
                },
                onCancel: () => {
                  window.location.href = configs.telLink;
                  setWarningDialog(null);
                },
                textSubmit: t("warning.carInfo.retake"),
                textCancel: t("warning.carInfo.contact", {
                  contactNumber: configs.contactNumber,
                }),
              });
            }
          },
        });
      };
      handleCheckCarStatus();
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (!carDetectionInfo || !carImage) return;

    setLoading(true);
    const isException = !!Object.values(carImage).find(
      (value) => value.failReason || value.damageResult.hasDamage
    );
    carSubmitMutate(
      {
        userRemark: {
          aiResultAccept: true,
          remark: "",
        },
      },
      {
        onSuccess: () => {
          navigate(CAR_SUCCESS_PATH, {
            state: { isException },
          });
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
  };

  return (
    <div className="flex flex-col h-full justify-between">
      {Object.entries(carConfig).map(([key, config]) => {
        const imageData = carImage?.[key as keyof typeof carImage] ?? null;
        let failReason = imageData?.failReason ?? "";
        if (
          imageData?.failType === AiFailType.unavailable ||
          failReason === AiFailReason.FailDamageDetection
        ) {
          // check FailDamageDetection because FailDamageDetection fail = Service Unavailable
          failReason = AiFailType.unavailable;
        } else if (failReason === FAIL_REASON_IMAGE_TYPE.code) {
          // define image type car or dashboard
          failReason =
            config.position === carConfig.dashboard.position
              ? FAIL_REASON_IMAGE_TYPE.dashboard
              : FAIL_REASON_IMAGE_TYPE.car;
        } else if (imageData?.damageResult?.hasDamage && !failReason) {
          failReason = AiFailReason.FailDamageDetection;
        }

        return (
          <div
            key={config.position}
            className="mb-4"
            data-testid={`cardetection-info-${config.position}`}
          >
            <CarDetailCard
              percent={imageData?.progress ?? 0}
              carConfig={config}
              t={t}
              failReason={failReason}
              carInspectionId={carInspectionId}
              status={imageData?.status ?? ""}
            />
          </div>
        );
      })}
      <div className="py-3">
        <Button
          fullWidth
          type="submit"
          variant="gradient"
          color="primary"
          size="large"
          onClick={() => setConfirm(true)}
          disabled={disabledSubmit}
          data-testid="cardetection-info-submit-btn"
        >
          {t("confirmBtn")}
        </Button>
      </div>
      <div className="py-3">
        <Button
          fullWidth
          variant="gradient"
          color="secondary"
          size="large"
          onClick={() => {
            setIsOpenDialogContactStaff(true);
          }}
          data-testid="cardetection-info-contactAdmin-btn"
        >
          {t("contactAdmin")}
        </Button>
      </div>
      <DialogContactStaff
        contactType={MailContactType.CarDetection}
        refNo={refNo}
        open={isOpenDialogContactStaff}
        onCloseDialog={() => {
          setIsOpenDialogContactStaff(false);
        }}
      />
      {confirm && (
        <DialogConfirm
          title={t("car.confirmDialog.title")}
          message={t("car.confirmDialog.message")}
          open={confirm}
          onClose={() => {
            setConfirm(false);
          }}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default CarInfoPage;
