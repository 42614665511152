import Axios, { AxiosResponse, AxiosError } from "axios";
import { configs } from "configs/config";
import { logTags } from "configs/logging";
import { captureLogException } from "utils/logging";
import { formatFormDataToObject } from "utils/formatter";
import { v4 as uuidv4 } from 'uuid';

const TIMEOUT = 300000; // 5 mins

export const axios = Axios.create({
  baseURL: configs.apiUrl,
  timeout: TIMEOUT,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosBaseApi = Axios.create({
  baseURL: configs.caraiBaseApiUrl,
  timeout: TIMEOUT,
  headers: {
    "Content-Type": "application/json",
  },
});

let requestData = {};

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    requestData = {};
    return response;
  },
  (error: AxiosError) => {
    logResponseError(error);
    requestData = {};
    return Promise.reject(error);
  }
);

axiosBaseApi.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    logResponseError(error);
    return Promise.reject(error);
  }
);

const logResponseError = (error: AxiosError) => {
  captureLogException(
    error,
    {
      ...logTags.request,
      requestURL: error?.config?.url ?? "",
    },
    {
      url: error?.request?.responseURL,
      code: error?.code,
      message: error?.message,
      respData: error?.response?.data,
      status: error?.response?.status || error?.request?.status,
      header: error?.response?.headers,
      bodyParam: requestData,
    }
  );
};

axios.interceptors.request.use(
  (config) => {
    let transaction = localStorage.getItem("transaction");
    if (!transaction) {
      transaction = uuidv4();
      localStorage.setItem("transaction", transaction);
    }
    
    config.headers = {
      ...config?.headers,
      apikey: configs.mtiApiKey,
      Transaction: transaction,
    };

    if (config.data) {
      requestData = config.data;
      if (config.data instanceof FormData) {
        const json = formatFormDataToObject(config.data);
        requestData = json;
      }
    }

    if (config.method === "get") {
      return {
        ...config,
        params: {
          ...config?.params,
          username: configs.mtiApiUsername,
          password: configs.mtiApiPassword,
          p_CallBy: configs.mtiApiPartnerCode,
        },
      };
    }

    return config;
  },
  (error) => {
    captureLogException(
      error,
      {
        ...logTags.request,
        requestURL: error?.config?.url,
      },
      ...error
    );
    return Promise.reject(error);
  }
);

export const setAxiosHeaderAuthorization = (bearerToken?: string): void => {
  if (bearerToken) {
    axiosBaseApi.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${bearerToken}`;
  } else {
    delete axiosBaseApi.defaults.headers.common["Authorization"];
  }
};
